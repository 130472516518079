<template>
  <b-overlay :show="loading">
    <div>
      <b-card class="border mb-2">
        <b-form @submit.prevent>
          <app-collapse>
            <app-collapse-item title="Búsqueda">
              <b-row>
                <b-col md="4">
                  <b-form-group>
                    <h5 class="font-weight-bold">
                      Inscripción
                    </h5>
                    <b-form-input
                      id="ficha"
                      v-model="filter.num_ficha"
                      placeholder="N° de Inscripción"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group>
                    <h5 class="font-weight-bold">
                      Orden de Compra
                    </h5>
                    <b-form-input
                      id="ordenCompra"
                      v-model="filter.num_orden_compra"
                      placeholder="N° de Orden de Compra"
                    />
                  </b-form-group>
                </b-col>
                <b-col md="4">
                      <b-form-group>
                          <h5 class="font-weight-bold">
                              Estado
                          </h5>
                          <v-select
                                  v-model="filter.estado_evaluacion"
                                  :options="estadosAlumnos"
                                  :reduce="(estado) => estado.id"
                                  placeholder="Seleccione Estado"
                          />
                      </b-form-group>
                  </b-col>
                <b-col class="text-right">
                  <b-button
                    type="submit"
                    variant="primary"
                    class="ml-1"
                    @click="getConsolidadoResumen"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      class="mr-50"
                    />
                    <span class="align-middle">Buscar</span>
                  </b-button>
                </b-col>
              </b-row>
            </app-collapse-item>
          </app-collapse>
        </b-form>
      </b-card>
      <b-col
              cols="12"
              v-if="generales"
      >
        <table class="mt-2 mt-xl-0 w-100 text-left">
          <tr>
            <th class="pb-50">
              <feather-icon
                      icon="TagIcon"
                      class="mr-75"
              />
              <span>Ficha: </span>
              <span class="font-weight-bold">{{resumenAlmunos[0] ? resumenAlmunos[0].num_ficha : ''}}</span>
            </th>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                      icon="FileTextIcon"
                      class="mr-75"
              />
              <span>Orden de compra: </span>
              <span class="font-weight-bold">{{resumenAlmunos[0] ? resumenAlmunos[0].num_orden_compra: ''}}</span>
            </th>

          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                      icon="BookOpenIcon"
                      class="mr-75"
              />
              <span>Nombre del Curso: </span>
              <span class="font-weight-bold">{{resumenAlmunos[0] ? resumenAlmunos[0].nombre_curso: ''}}</span>
            </th>

          </tr>
        </table>
      </b-col>
      <b-row>

        <b-col
          sm="12"
          md="6"
          class="my-1 text-right"
        >
          <div class="d-flex align-items-center mb-1 mb-md-0">
            <label>Mostrar</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              :options="pageOptions"
              size="sm"
              class="mx-50 col-md-2"
              @change="onChangePerPage"
            />
            <label>filas por página</label>
          </div>
        </b-col>
        <b-col
                sm="4"
                md="6"
                class="my-1"
        >
          <div class="d-flex justify-content-end">
            <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="primary"
                    type="submit"
                    class="ml-1"
                    @click="exportar"
            >
              <feather-icon
                      icon="DownloadIcon"
                      class="mr-50"
              />
              <span class="align-middle">Exportar</span>

            </b-button>
          </div>
        </b-col>
        <b-col
          cols="12"
        >

          <b-table
            id="my-table"
            ref="table"
            striped
            hover
            small
            :per-page="perPage"
            :items="resumenAlmunos"
            :fields="fields"
            responsive
            class="mb-0"
            show-empty
          >
            <template #cell()="data">
              {{ data.value ? data.value : '-' }}
            </template>
            <template #cell(estado_evaluacion)="data">
              <b-badge :variant="colorEstado(data.item.estado_evaluacion)">
                {{ data.item.estado_evaluacion ? data.item.estado_evaluacion : '-' }}
              </b-badge>
            </template>
            <template #empty="scope">
              <div class="text-center">No existen resultados</div>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row class="pl-1">
        <b-col
          cols="8"
          class="mt-3"
        >
          <div v-if="totalRows > 0">
            <span class="text-muted">  Mostrando del {{ from }} al {{ to }} de {{ totalRows }} filas</span>
          </div>
        </b-col>

        <b-col cols="4">
          <b-pagination
            :total-rows="totalRows"
            :value="page"
            :per-page="perPage"
            align="right"
            class="mt-3 mr-1"
            aria-controls="my-table"
            @change="onPageChange"
          />
        </b-col>

      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import {
  BTable, BButton, BRow, BCol, BFormGroup, BFormInput, BCard, BBadge, BPagination, BFormSelect,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  name: 'ConsolidadoAlumno',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BTable,
    BBadge,
    AppCollapse,
    AppCollapseItem,
    BPagination,
    BFormSelect,
  },
  data() {
    return {
      generales: false,
      perPage: 50,
      page: 1,
      pageOptions: [50, 100, 500, 1000],
      estadosAlumnos: [
            { id: 'Aprobado', label: 'Aprobado' },
            { id: 'Reprobado', label: 'Reprobado' },
            { id: 'No iniciado', label: 'No iniciado' },
            { id: 'Sin nota', label: 'Sin nota' },
            { id: 'No aplica', label: 'No aplica' },
      ],
      filter: {
        num_ficha: null,
        num_orden_compra: null,
        estado_evaluacion: null,
      },
      fields: [

        { key: 'nombre' },
        { key: 'apellidos' },
        { key: 'rut' },
        { key: 'nombre_curso' },
        { key: 'estado_evaluacion', label: 'Estado', tdClass: 'text-center', thClass: 'text-center' },
        { key: 'num_ficha', label: 'Inscripción' },
        { key: 'num_orden_compra', label: 'OC ' },
        { key: 'fecha_inicio', label: 'Fecha Inicio', tdClass: 'text-center', thClass: 'text-center' },
        { key: 'fecha_cierre', label: 'Fecha Cierre', tdClass: 'text-center', thClass: 'text-center' },
        //{ key: 'aprobado', tdClass: 'text-center', thClass: 'text-center' },
        //{ key: 'desaprobado', tdClass: 'text-center', thClass: 'text-center' },
        //{ key: 'sin_entrar_curso', label: 'Entro al curso', tdClass: 'text-center', thClass: 'text-center' },

      ],
    }
  },
  computed: {
    resumenAlmunos() {
      return this.$store.state.alumnos.consolidado
    },
    config() {
      return {
        locale: require('flatpickr/dist/l10n/es.js').default.es,
        dateFormat: 'd-m-Y',
        wrap: true,
      }
    },
    totalRows() {
      return this.$store.state.alumnos.rowsTotal
    },
    to() {
      return this.$store.state.alumnos.to
    },
    from() {
      return this.$store.state.alumnos.from
    },
    listado() {
      return this.$store.state.alumnos.list
    },
    currentPage() {
      return this.$store.state.alumnos.currentPage
    },
    loading() {
      return this.$store.state.alumnos.loading
    },
  },
  mounted() {
    this.resumenAlmunos = []
  },
  methods: {
    getConsolidadoResumen() {
      const filterData = {
        filter: {
          num_ficha: this.filter.num_ficha,
          num_orden_compra: this.filter.num_orden_compra,
          estado_evaluacion: this.filter.estado_evaluacion,
        },
        page: this.page,
        perPage: this.perPage,
      }
      this.$store.dispatch('alumnos/getConsolidado', filterData)
    },
    exportar() {
      if (this.totalRows == 0) {
        this.$bvToast.toast('Debe realizar una búsqueda antes de exportar', {
          title: 'ALERTA!!',
          variant: 'danger',
          solid: true,
        })
      } else {
        this.getExportData()
      }
    },
    getExportData() {
      const filterData = {
        filter: {
          num_ficha: this.filter.num_ficha,
          num_orden_compra: this.filter.num_orden_compra,
          estado_evaluacion: this.filter.estado_evaluacion,
        },
        page: this.page,
        item_per_page: this.perPage,
      }
      this.$store.dispatch('alumnos/exportData', filterData)
    },
    selectCurso(curso) {
      this.filter.courseId = curso != null ? curso.id : null
    },
    onPageChange(page) {
      this.page = page
      this.getConsolidadoResumen()
    },
    onChangePerPage() {
      this.page = 1
      this.getConsolidadoResumen()
    },
    colorEstado(estado) {
      switch (estado) {
        case 'Aprobado':
          return 'light-success'
          break;
        case 'Reprobado':
          return 'light-danger'
          break;
        case "No iniciado":
          return 'light-dark'
          break;
        case "Sin nota":
          return 'light-warning'
          break;
        case "No aplica":
          return 'light-dark'
          break;
        case null:
          return 'light-dark'
          break;
      }
    },
  },
}
</script>
<style scoped lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    .demo-inline-spacing.curso .custom-radio{
        margin-top: 0.5rem!important;

    }
    .btn-clear {
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #d8d6de;
        border-radius: 0px 3px 3px 0px!important;
        color: #6e6b7b;
    }
</style>
