<template>
    <b-card>
    <b-tabs>
        <b-tab active title="Inscripciones">
            <consolidado-ficha></consolidado-ficha>
        </b-tab>
        <b-tab
                title="Alumnos"
        >
            <consolidado-alumno></consolidado-alumno>
        </b-tab>
    </b-tabs>
    </b-card>
</template>

<script>
    import ConsolidadoFicha from '@/views/consolidadoFicha/ConsolidadoFicha.vue'
    import consolidadoAlumno from '@/views/consolidadoAlumno/ConsolidadoAlumno.vue'
    export default {
        name: "Consolidado",
        components: {
            'consolidado-ficha': ConsolidadoFicha,
            'consolidado-alumno': consolidadoAlumno,
        },    }
</script>

<style scoped>

</style>