<template>
  <b-overlay :show="loading">
  <div>
    <b-card class="border mb-2">
      <b-form @submit.prevent>
        <app-collapse>
          <app-collapse-item title="Búsqueda">
            <b-row>
      <b-col md="3">
        <b-form-group>
          <h5 class="font-weight-bold">
            Fecha Desde
          </h5>
          <div class="input-group">
            <flat-pickr
                    v-model="filter.initDate"
                    class="form-control"
                    placeholder="Click para seleccionar..."
                    :config="config"
            />
            <div class="input-group-append">
              <button class="btn-clear" type="button" title="Limpiar" data-clear>
                <feather-icon
                        icon="XIcon"
                />
              </button>
            </div>
          </div>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group>
          <h5 class="font-weight-bold">
            Fecha Hasta
          </h5>
          <div class="input-group">
            <flat-pickr
                    v-model="filter.endDate"
                    class="form-control"
                    placeholder="Click para seleccionar..."
                    :config="config"
            />
            <div class="input-group-append">
              <button class="btn-clear" type="button" title="Limpiar" data-clear>
                <feather-icon
                        icon="XIcon"
                />
              </button>
            </div>
          </div>
        </b-form-group>
      </b-col>
      <b-col md="6">
                <b-form-group
                >
                  <h5 class="font-weight-bold">
                    Cursos
                  </h5>
                  <div class="form-label-group">
                    <b-form-group>
                      <autocomplete-infinity
                              url="entidades/course-version-without-presencial-sychronous-modality"
                              placeholder="Seleccione Curso"
                              @item-selected="selectCurso"
                              label="description"
                              itemsPerPage="10"
                              id="curso"
                      ></autocomplete-infinity>

                    </b-form-group>
                  </div>
                </b-form-group>
              </b-col>
      <b-col md="6">
        <b-form-group>
          <h5 class="font-weight-bold">
            Inscripción
          </h5>
          <b-form-input
                  id="ficha"
                  v-model="filter.cardNumber"
                  placeholder="N° de Inscripción"
          />
        </b-form-group>
      </b-col>
      <b-col md="6">
        <b-form-group>
          <h5 class="font-weight-bold">
            Orden de Compra
          </h5>
          <b-form-input
                  id="ordenCompra"
                  v-model="filter.numberOfOrderBuy"
                  placeholder="N° de Orden de Compra"
          />
        </b-form-group>
      </b-col>
      <b-col class="text-right">
        <b-button
          type="submit"
          variant="primary"
          class="ml-1"
          @click="getConsolidadoResumen">
          <feather-icon
                  icon="SearchIcon"
                  class="mr-50"
          />
          <span class="align-middle">Buscar</span>
        </b-button>
      </b-col>
    </b-row>
          </app-collapse-item>
        </app-collapse>
      </b-form>
    </b-card>

    <b-row>
      <b-col
              sm="12"
              md="6"
              class="my-1 text-right"
      >
        <div class="d-flex align-items-center mb-1 mb-md-0">
          <label>Mostrar</label>
          <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  :options="pageOptions"
                  size="sm"
                  class="mx-50 col-md-2"
                  @change="onChangePerPage"
          />
          <label>filas por página</label>
        </div>
      </b-col>
      <b-col
              sm="4"
              md="6"
              class="my-1"
      >
        <div class="d-flex justify-content-end">
          <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="primary"
                  type="submit"
                  class="ml-1"
                  @click="exportar"
          >
            <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
            />
            <span class="align-middle">Exportar</span>

          </b-button>
        </div>
      </b-col>
      <b-col
              cols="12"
      >

          <b-table
                  id="my-table"
                  ref="table"
                  striped
                  hover
                  small
                  :per-page="perPage"
                  :items="resumenDataAlmunos"
                  :fields="fields"
                  responsive
                  class="mb-0"
                  show-empty
          >
            <template #cell()="data">
              {{ data.value ? data.value : '-' }}
            </template>
            <template #cell(cantidad_alumnos)="data">
              <div class="text-center">
                <b-badge v-if="data.item.cantidad_alumnos" variant="light-dark">
                  {{ data.item.cantidad_alumnos}}
                </b-badge>
              </div>
            </template>
            <template #cell(aprobados)="data">
              <div class="text-center">
                <b-badge  variant="light-success">
                  {{ data.item.aprobados}}
                </b-badge>
              </div>
            </template>
            <template #cell(desaprobados)="data">
              <div class="text-center">
                <b-badge variant="light-danger">
                  {{ data.item.desaprobados}}
                </b-badge>
              </div>
            </template>
            <template #cell(alumnos_sin_entrar_curso)="data">
              <div class="text-center">
                <b-badge variant="light-primary">
                  {{ data.item.alumnos_sin_entrar_curso}}
                </b-badge>
              </div>
            </template>
            <template #cell(sin_realizar_evaluaciones)="data">
              <div class="text-center">
                <b-badge variant="light-warning">
                  {{ data.item.sin_realizar_evaluaciones}}
                </b-badge>
              </div>
            </template>
            <template #empty="scope">
              <div class="text-center">No existen resultados</div>
            </template>
          </b-table>
      </b-col>
    </b-row>
    <b-row class="pl-1">
      <b-col
              cols="8"
              class="mt-3"
      >
        <div v-if="totalRows > 0">
          <span class="text-muted">  Mostrando del {{ from }} al {{ to }} de {{ totalRows }} filas</span>
        </div>
      </b-col>

      <b-col cols="4">
        <b-pagination
                :total-rows="totalRows"
                :value="page"
                :per-page="perPage"
                align="right"
                class="mt-3 mr-1"
                aria-controls="my-table"
                @change="onPageChange"
        />
      </b-col>

    </b-row>
  </div>
  </b-overlay>
</template>

<script>
import { BTable, BButton, BRow, BCol, BFormGroup, BFormInput, BCard, BBadge, BPagination, BFormSelect} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  name: 'ConsolidadoAlumnos',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    flatPickr,
    BCard,
    BTable,
    BBadge,
    AppCollapse,
    AppCollapseItem,
    BPagination,
    BFormSelect,
  },
  data() {
    return {
      perPage: 50,
      page: 1,
      pageOptions: [50, 100, 500, 1000],
      filter: {
        cardNumber: null,
        numberOfOrderBuy: null,
        initDate: null,
        endDate: null,
        courseId: null,
      },
      fields: [
        { key: 'num_ficha', label: 'Inscripción' },
        { key: 'num_orden_compra', label: 'OC ' },
        { key: 'curso' },
        { key: 'cantidad_alumnos', label: 'Cantidad Alumnos', tdClass: 'text-center', thClass: 'text-center' },
        { key: 'aprobados', label: 'Cantidad Aprobados', tdClass: 'text-center', thClass: 'text-center' },
        { key: 'desaprobados', label: 'Cantidad Reprobados', tdClass: 'text-center', thClass: 'text-center' },
        { key: 'alumnos_sin_entrar_curso', label: 'Sin entrar al Curso', tdClass: 'text-center', thClass: 'text-center' },
        { key: 'sin_realizar_evaluaciones', label: 'Sin realizar evalución', tdClass: 'text-center', thClass: 'text-center' },
        { key: 'estado', tdClass: 'text-center', thClass: 'text-center' },
        { key: 'fecha_inicio', label: 'Fecha Inicio', tdClass: 'text-center', thClass: 'text-center' },
        { key: 'fecha_cierre', label: 'Fecha Cierre', tdClass: 'text-center', thClass: 'text-center' },
      ],
    }
  },
  computed: {
    resumenDataAlmunos() {
      return this.$store.state.consolidado.consolidadoAlumnos
    },
    config() {
      return {
        locale: require('flatpickr/dist/l10n/es.js').default['es'],
        dateFormat: "d-m-Y",
        wrap: true,
      }
    },
    totalRows() {
      return this.$store.state.consolidado.rowsTotal
    },
    to() {
      return this.$store.state.consolidado.to
    },
    from() {
      return this.$store.state.consolidado.from
    },
    listado() {
      return this.$store.state.consolidado.list
    },
    currentPage() {
      return this.$store.state.consolidado.currentPage
    },
    loading() {
      return this.$store.state.consolidado.loading
    },
  },
  mounted() {
    this.getConsolidadoResumen()
  },
  methods: {
    getConsolidadoResumen() {
      let fechaInicio = null
      if(this.filter.initDate != null && this.filter.initDate != ''){
        fechaInicio = this.filter.initDate.split("-")
        fechaInicio = fechaInicio[2] +'-'+ fechaInicio[1] +'-'+ fechaInicio[0]
      }
      let fechaFin = null
      if(this.filter.endDate != null && this.filter.endDate != '') {
        fechaFin = this.filter.endDate.split("-")
        fechaFin = fechaFin[2] +'-'+ fechaFin[1] +'-'+ fechaFin[0]
      }
      let filterData = {
        'filter': {
          'cardNumber': this.filter.cardNumber,
          'numberOfOrderBuy': this.filter.numberOfOrderBuy,
          'initDate': fechaInicio,
          'endDate': fechaFin,
          'courseId': this.filter.courseId,
        },
        'page': this.page,
        'perPage': this.perPage,
      }
      this.$store.dispatch('consolidado/getConsolidadoResumen', filterData)
    },
    selectCurso(curso) {
      this.filter.courseId = curso != null ? curso.id : null
    },
    onPageChange(page) {
      this.page = page
      this.getConsolidadoResumen()
    },
    onChangePerPage() {
      this.page = 1
      this.getConsolidadoResumen()
    },
    exportar() {
      if (this.totalRows == 0) {
        this.$bvToast.toast('Debe realizar una búsqueda antes de exportar', {
          title: 'ALERTA!!',
          variant: 'danger',
          solid: true,
        })
      } else {
        this.getExportData()
      }
    },
    getExportData() {
      let fechaInicio = null
      if(this.filter.initDate != null && this.filter.initDate != ''){
        fechaInicio = this.filter.initDate.split("-")
        fechaInicio = fechaInicio[2] + '-' + fechaInicio[1] +'-'+ fechaInicio[0]
      }
      let fechaFin = null
      if(this.filter.endDate != null && this.filter.endDate != '') {
        fechaFin = this.filter.endDate.split("-")
        fechaFin = fechaFin[2] + '-' + fechaFin[1] + '-' + fechaFin[0]
      }
      let filterData = {
        'filter': {
          'cardNumber': this.filter.cardNumber,
          'numberOfOrderBuy': this.filter.numberOfOrderBuy,
          'initDate': fechaInicio,
          'endDate': fechaFin,
          'courseId': this.filter.courseId,
        },
        'page': this.page,
        'perPage': this.perPage,
      }
      this.$store.dispatch('consolidado/exportData', filterData)
    },
  },
}
</script>
<style scoped lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .demo-inline-spacing.curso .custom-radio{
    margin-top: 0.5rem!important;

  }
  .btn-clear {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0px 3px 3px 0px!important;
    color: #6e6b7b;
  }
</style>

